import { deferLoading, loadingDone, navigateTo } from '@mfl/framework';
import { AccountBasicInfoResponse, userGateway } from '@msl/user-gateway-sdk';
import { userAnalyticsGateway } from '@msl/user-analytics-gateway-sdk';

import { auth4Sdks } from './auth';
import { doHeaderSetup } from './header';

export enum PlatformBootSequence {
  Normal,
  SkipPlugins,
  Stop,
}
import { WEBAPP_BASE_URL } from './consts';
export async function platformBootstrap(): Promise<PlatformBootSequence> {
  console.info('platformBootstrap start');

  let accountData: AccountBasicInfoResponse | undefined;
  try {
    deferLoading();
    await userGateway.init(auth4Sdks());
    await userAnalyticsGateway.init(auth4Sdks());
    accountData = await userGateway.accountBasicInfo({});
    const sequence = handleOnBoardingRedirects(accountData);

    if (sequence !== PlatformBootSequence.Normal) return sequence;

    doHeaderSetup(accountData);
  } catch (e) {
    console.error('get account basic info failed', e);
    throw e;
  } finally {
    loadingDone();
  }
  return PlatformBootSequence.Normal;
}

const ONBOARDING_ROUTE = '/v1/onboarding';

function handleOnBoardingRedirects(
  accountData: AccountBasicInfoResponse
): PlatformBootSequence {
  const isInOnboarding: boolean = location.pathname === ONBOARDING_ROUTE;
  const { signupFlowCompleted } = accountData;

  if (!signupFlowCompleted && !isInOnboarding) {
    navigateTo(ONBOARDING_ROUTE);

    return PlatformBootSequence.SkipPlugins;
  }

  if (signupFlowCompleted && isInOnboarding) {
    navigateTo(
      WEBAPP_BASE_URL + '/',
      false,
      true /* reload - so webapp loads */
    );

    return PlatformBootSequence.Stop;
  }

  return isInOnboarding
    ? PlatformBootSequence.SkipPlugins
    : PlatformBootSequence.Normal;
}
