import { AccountBasicInfoResponse } from '@msl/user-gateway-sdk';

import {
  headerAnnouncementComponents,
  headerRightComponents,
} from '@mfe/core-header';

import HeaderUserMenu from './header-user-menu.vue';
import HeaderActionButtons from './header-action-buttons.vue';
import ImpersonationAnnouncementBar from './impersonation-announcement-bar.vue';
import HeaderAnnouncementBar from './header-announcement-bar.vue';

export function doHeaderSetup(accountData: AccountBasicInfoResponse) {
  // PROMOTIONS
  headerRightComponents.register({
    key: 'PLATFORM_ACTION_BUTTONS',
    component: HeaderActionButtons,
    props: accountData,
  });

  // USER MENU
  headerRightComponents.register({
    key: 'PLATFORM_USER_MENU',
    component: HeaderUserMenu,
  });

  // IMPERSONATION
  const isImpersonated = sessionStorage.getItem('isImpersonated');

  if (isImpersonated) {
    headerAnnouncementComponents.register({
      key: 'PLATFORM_IMPERSONATION',
      component: ImpersonationAnnouncementBar,
      props: accountData,
    });
  }

  // ANNOUNCEMENT
  headerAnnouncementComponents.register({
    key: 'PLATFORM_PROMOTION_ANNOUNCEMENT',
    component: HeaderAnnouncementBar,
    props: accountData,
  });
}

const ONBOARDING_ROUTE = '/v1/onboarding';

function handleOnBoardingRedirects(
  accountData: AccountBasicInfoResponse
): PlatformBootSequence {
  const isInOnboarding: boolean = location.pathname === ONBOARDING_ROUTE;
  const { signupFlowCompleted } = accountData;

  if (!signupFlowCompleted && !isInOnboarding) {
    navigateTo(ONBOARDING_ROUTE);

    return PlatformBootSequence.SkipPlugins;
  }

  if (signupFlowCompleted && isInOnboarding) {
    navigateTo(
      WEBAPP_BASE_URL + '/',
      false,
      true /* reload - so webapp loads */
    );

    return PlatformBootSequence.Stop;
  }

  return isInOnboarding
    ? PlatformBootSequence.SkipPlugins
    : PlatformBootSequence.Normal;
}
